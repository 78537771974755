/*------------------------------------------------------------*\
    Panels
\*------------------------------------------------------------*/

.panel {
	background: lighten($brand-primary, 10);
	color: #fff;

	.panel-heading {
		font-size: 18px;
		font-weight: 600;
		background: lighten($brand-primary, 10);
		color: #fff;
		border: 0;
	}
	.panel-body {
		.btn {
			font-size: 16px;
		}
	}
}