/*------------------------------------------------------------*\
    Footer
\*------------------------------------------------------------*/

footer {
    background: #E7F1FB;
    margin-top: 20px;
    padding-top: 30px;
    padding-bottom: 30px;

    .footer-block {
        color: $brand-primary;

        a {
            color: $brand-primary;
        }
        ul {
            list-style: none;
            padding: 0;
        }
    }
}
.footer-bottom {
    padding: 10px 0;
    background: darken(#E7F1FB, 10);
    font-size: 12px;
}