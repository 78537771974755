/*------------------------------------------------------------*\
    Global
\*------------------------------------------------------------*/

body {

}

img {
	max-width: 100%;
	height: auto;
}

a, .btn {
	outline: none;

	&:hover, &:focus, &:active {
		outline: none;
	}
}